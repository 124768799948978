import { createRouter, createWebHistory } from 'vue-router';
import { useStoreSite } from '@/stores/store.site'
import { useGtm } from '@gtm-support/vue-gtm';
import Utils from './helpers/Utils';

const routes = [
  {
    path: '/document-sign',
    name: 'document-sign',
    redirect: '/document-sign/review/invalid',
    children: [
      {
        path: "review",
        redirect: '/document-sign/review/invalid',
      },
      {
        path: "review/:token",
        name: 'document-sign-review',
        component: () => import('./views/document-sign/Sign.Verify.vue'),
        meta: { layout: "blank", requireAuth: false },
      },
    ],
  },
  {
    path: "/test",
    name: 'for-test',
    component: () => import('./views//Site.Test.vue'),
    meta: { layout: "blank", requireAuth: false },
  },
  {
    path: '/:catchAll(.*)*',
    name: 'bad-not-found',
    //redirect: '/',
    component: () => import('./views/Site.NotFound.vue'),
    meta: { layout: 'blank' },
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      const pos = savedPosition || to.meta?.scrollPos || { top: 0, left: 0 };
      setTimeout(() => {
        // //console.log("savedPosition to ---->", to);
        resolve(pos);
      }, 200);
    });
  },
});

router.afterEach(async (to, from) => {
  if (to.meta.layout) {
    const storeSite = useStoreSite();
    storeSite.setLayout(to.meta.layout);
  }
  const gtm = useGtm();
  if (to.path) {
    gtm.trackEvent({
      event: 'content-view',
      pageTitle: to.path === "/" ? "MeyzerSign - Landing" : `MeyzerSign - ${Utils.formatPath(to.path)}`,
      pageUrl: window.location.href
    })
  }
});

export default router;
